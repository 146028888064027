.disconnect-button-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: max-content;
  height: 46px;
}

.wallet-button {
  outline: none !important;
  background: white;
  /*width: 100%;*/
  border: 1px solid #E8E8EB !important;
  transition: all 0.1s 0.2s ease-out !important;
  display: flex;
  color: black;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border-radius: 25px !important;
  padding: 8px !important;
  padding-right: 16px !important;
  overflow: hidden;
  height: 46px;
  z-index: 1;
}

.wallet-button.not-connected {
  color: white;
}

.swoosh {
  transition: all 0.2s 0s;
  position: absolute;
  top: calc(100% - 22px);
  right: calc(100% - 1px);
  transform: scaleX(0);
  transform-origin: right;
  z-index: 1;

  &.open {
    transition: all 0.2s 0.2s;
    transform: scaleX(1);
  }
}

.wallet-button.connected:hover {
  /*background: rgba(199, 0, 0, 0.6);*/
}

.wallet-button.not-connected {
  padding: 10px !important;
  font-weight: bolder;
  background: #33CC66;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.wallet-button.not-connected.open{
  color: black;
}

.wallet-button.not-connected.open:hover {
  background: white;
}

.wallet-button.open {
  border-bottom:  1px solid white !important;
  transition: all 0.2s ease-in !important;
  background: white;
  border-radius: 22px 22px 0 0 !important;
  height: calc(46px + 10px);
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.15);

}

.connect-title {
  /*width: 150px;*/
  font-weight: bold;
  font-size: 14px;
  min-height: 26px;
  padding-right: 8px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connect-title.open {
  /*width: 150px;*/
  /*border-bottom: 1px solid #8D8DA3;*/
  /*padding-bottom: 8px;*/
  /*margin-bottom: 4px;*/
}

.connect-wallet-flex {
  transition: all 0.3s ease-in;
  position: absolute;
  top: calc(100% + 9px);
  right: 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
  background: white;
  border: 1px solid #E8E8EB;
  border-radius: 20px 0 20px 20px;
  transform-origin: top right;
  overflow: hidden;
  transform: scaleY(0);
}

.connect-wallet-flex.not-connected {
  .connection-button {
    height: 50px;
  }
}

.connect-wallet-flex.open {
  transition: all 0.2s 0.3s ease-out;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

  transform: scaleY(1);
}

.connector-options {
  //transition: all 0.3s;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  /*height: 0;*/
}

.connector-options .connection-button {
  transition: all 0.2s ease-in, background 0.1s;
  height: 50px;
  opacity: 0;
  .bordered {
    border-top: 1px solid #A4A4A4;
    border-bottom: 1px solid #A4A4A4;
  }
}

.connector-options.open .connection-button {
  transition: all 0.4s ease-out, background 0.1s;
  opacity: 1;
}

.wallet-button.not-connected:hover {
  background: #33CC66;
}

.connection-button {
  cursor: pointer;
  outline: none !important;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  margin: 0;
  padding-right: 18px;
  padding-left: 18px;
  width: max-content;
  min-width: 100%;
  /*font-size: 16px;*/

  &.accented {
    background: rgba(51, 204, 102, 0.4);
  }
}

.notification-bubble {
  transition: all 0.3s;
  position: absolute;
  top: -6px;
  right: -6px;
  padding: 0px 6px;
  font-size: 12px;
  border-radius: 9px;
  background: #33CC66;
  color: white;
  font-weight: bolder;
  overflow: hidden;
  z-index: 2000;
  transform: scaleX(1) scaleY(1);
  transform-origin: bottom left;
  min-width: 20px;
  min-height: 20px;

  &.hiding {
    min-width: 0px;
    min-height: 0px;
    transform: scaleX(0) scaleY(0);
  }
}

.bordered {
  border: 0px solid #A4A4A4;
  margin-right: 4px;
  margin-left: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 14px;
  padding-left: 14px;
}

.connection-button:hover {
  background: rgba(0, 0, 0, 0.3);
}

.account-address {
  font-size: 14px;
}