.input-container.style-override{
    width: 100%;
    margin-bottom: 16px;

    .SimpleInput{
        &:hover{
        }

        &.not-valid{
        }

        &::placeholder{
        }

        &::-webkit-outer-spin-button{
            -webkit-appearance: none;
            margin: 0;
        }
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus{
        }
    }
    .simple-input-form{
    }


    .default-value-button{
        color: black !important;
    }

    .default-value-button:hover{
    }

    .simple-input-label{
    }

    .validation-error-tooltip{
    }
}

